<template>
  <CRow>
    <CCol col="12">
      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit payment') + ` id: ${form.id}` : $t('Create payment') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard>
              <AMultiSelect :disabled="true" :horizontal="{ input: 'col-sm-6' }" name="month_id" label="Month" v-model="form.month_id" :options="lists.months" :isValid="isValid('month_id')" :errors="getErrors('month_id')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-6' }" name="building_id" label="Building" v-model="form.building_id" :options="lists.buildings" :isValid="isValid('building_id')" :errors="getErrors('building_id')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-6' }" name="bank_account_id" label="Bank account" v-model="form.bank_account_id" :options="lists.bank_accounts" :config="{ label: 'account_name' }" :isValid="isValid('bank_account_id')" :errors="getErrors('bank_account_id')" />
              <AInput :horizontal="{ input: 'col-sm-4' }" onkeypress="return false" type="date" label="Date" name="payment_date" v-model="form.payment_date" :isValid="isValid('payment_date')" :errors="getErrors('payment_date')" />

              <ATextArea label="Observations" v-model="form.observations" />

              <hr />
              <AInput label="Value" name="value" v-model="form.value" :isValid="isValid('value')" :errors="getErrors('value')" />
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" :disabled="is_disabled" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'PaymentForm',
  mixins: [formMixin],

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0 },

      // Helpers
      is_disabled: false,

      lists: {},
      alerts: [],
      objects: {},
      validators: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/property_payments' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.property_payment ? data.property_payment : { id: 0 }
      this.lists = data._lists || {}

      // TODO: function ?
      //this.is_disabled = !!this.form.id && this.property._periods?.FAC.month_id > this.form.month_id

      this.parseView()
      this.setObjects()
      this.parsedBuildings()

      this.validators = {
        main: data._validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)
    },

    // Helpers
    setObjects() {
      this.objects.building_types = {}
      for (const building_type of this.lists.building_types || []) {
        this.objects.building_types[building_type.id] = building_type
      }
      this.objects.property_units = {}
      for (const property_unit of this.lists.property_units || []) {
        this.objects.property_units[property_unit.id] = property_unit
      }
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create'), disabled: this.is_disabled }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Payment']: options })
    },
    parsedBuildings() {
      this.lists.buildings.forEach(item => {
        item.name = `(${this.objects.property_units[item.property_unit_id].name}) ${this.objects.building_types[item.building_type_id].name} - ${item.code}`
      })
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      const self = this
      self.form = self.stripNumbers(self.form, ['value'])

      console.log('AQUI')

      self.$http[self.form.id ? 'put' : 'post']('admin/property_payments', self.form) // TODO: on service ?
        .then(response => {
          self.showAlert(`Payment TEST`)

          console.log(response)

          self.$router.replace(`/admin/processes/payments/${response.data.property_payment.id}/edit`).catch(() => {})
          self.showAlert(`Payment ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the payment.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
